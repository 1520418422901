.tableWrapper {
  padding: 20px 30px;
}
.tableLayout {
  background-color: #fff;
  padding: 30px 25px;
}
.table {
  margin-top: 30px;
}
