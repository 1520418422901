.loginWrapper {
  width: 50%;
}
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.formWrapper {
  max-width: 400px;
}
.formWrapper input {
  width: 380px;
}
@media screen and (max-width: 900px) {
  .loginWrapper {
    width: 100%;
    padding: 0 20px;
  }
}
@media screen and (max-width: 450px) {
  .formWrapper input {
    width: 310px;
  }
}
