:root {
  --main-color: #43425d;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f0f2f5; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
.main {
  height: 100%;
}
.main-layout {
  height: 100%;
}
/* html,
body {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
} */
.content {
  padding: 20px 30px;
}

.main b {
  font-weight: bold;
}

@media only screen and (max-height: 970px) {
  .main {
    height: 100%;
  }
  html {
    height: auto;
  }
}
