.loginContainer {
  display: flex;
  height: 100vh;
}
.loginCoverContainer {
  width: 50%;
}
.loginCoverContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 900px) {
  .loginCoverContainer {
    display: none;
  }
}
