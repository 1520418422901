.header {
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
  z-index: 100;
  background-color: #fff;
}
.trigger {
  display: flex;
  justify-content: center;
  padding-top: 21px;
  padding-bottom: 21px;
}
.triggerActive {
  display: flex;
  justify-content: flex-end;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-right: 21px;
}
.triggerIcon {
  color: rgb(249, 250, 251);
  height: 1.3em;
  width: 1.3em;
  cursor: pointer;
}
