.loaderWrapper {
  width: 100vw;
  height: 100vh;
}
.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.iconContainer {
  position: absolute;
  bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}
